import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import { UserAPIService } from '../../Api/userAPIService';
import { useRecoilState } from "recoil";
import { userState } from '../../State/userAtom';
import { useNavigate } from 'react-router-dom';
import BottomNavBar from '../components/menu';
import styles from "./myBookings.module.css";
import BookingCard from './BookingCard';
import { groupBookingsByDate } from '../../Utils/utils';
import HoverEffectDiv from '../../effects/HoverEffectDiv';
import Footer from '../components/footer';
import ResponsiveAppBar from "../components/header/header_mui.tsx"

const userAPIService = new UserAPIService();

const mockFutureBookings = [
    {
        bookingDate: '2024-07-10',
        Space: { name: 'Meeting Room A', address: '123 Main St' },
        Worx: { name: 'Workplace A', WorxPhotos: [{ photo_url: 'https://via.placeholder.com/60' }] },
        from_time: '09:00',
        to_time: '11:00',
        totalAmount: 50.00
    },
    {
        bookingDate: '2024-07-15',
        Space: { name: 'Desk 1', address: '456 Elm St' },
        Worx: { name: 'Workplace B', WorxPhotos: [{ photo_url: 'https://via.placeholder.com/60' }] },
        from_time: '10:00',
        to_time: '16:00',
        totalAmount: 30.00
    }
];

const mockPastBookings = [
    {
        bookingDate: '2024-06-25',
        Space: { name: 'Desk 2', address: '789 Oak St' },
        Worx: { name: 'Workplace A', WorxPhotos: [{ photo_url: 'https://via.placeholder.com/60' }] },
        from_time: '09:00',
        to_time: '17:00',
        totalAmount: 40.00
    },
    {
        bookingDate: '2024-06-20',
        Space: { name: 'Conference Room', address: '101 Pine St' },
        Worx: { name: 'Workplace C', WorxPhotos: [{ photo_url: 'https://via.placeholder.com/60' }] },
        from_time: '13:00',
        to_time: '15:00',
        totalAmount: 60.00
    }
];

const fetchFutureBookings = async (userId) => {
    // Uncomment the below line to use real API data
    const response = await userAPIService.getFutureBookings(userId);
    return response.body.sort((a, b) => new Date(a.bookingDate) - new Date(b.bookingDate));

    // Use mock data for future bookings
    //return mockFutureBookings.sort((a, b) => new Date(a.bookingDate) - new Date(b.bookingDate));
};

const fetchPastBookings = async (userId) => {
    //Uncomment the below line to use real API data
    const response = await userAPIService.getPastBookings(userId);
    return response.body.sort((a, b) => new Date(b.bookingDate) - new Date(a.bookingDate));

    // Use mock data for past bookings
    //return mockPastBookings.sort((a, b) => new Date(b.bookingDate) - new Date(a.bookingDate));
};

export default function UserDashboard() {
    const [pastBookings, setPastBookings] = useState([]);
    const [comingBookings, setComingBookings] = useState([]);
    const [user, setUser] = useRecoilState(userState);
    const [isUpcoming, setIsUpcoming] = useState(true);
    const [showBookings, setShowBookings] = useState(true);
    let navigate = useNavigate();

    const isEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    };

    useEffect(() => {
        if (isEmpty(user)) {
            navigate("/");
        }

        fetchFutureBookings(user.bbdUser.user.id).then((response) => setComingBookings(response));
        fetchPastBookings(user.bbdUser.user.id).then((response) => setPastBookings(response));
    }, []);

    const renderBookings = (bookings) => {
        const groupedBookings = groupBookingsByDate(bookings);

        return Object.keys(groupedBookings).map((date) => (
            <div key={date} className={styles.groupedBookings}>
                <div className={styles.bookingCardDate}>
                    {date} <span className={styles.totalAmount}>Total: €{groupedBookings[date].totalAmount.toFixed(2)}</span>
                </div>
                {groupedBookings[date].bookings.map((booking, index) => (
                    <BookingCard
                        key={index}
                        space={booking.Space}
                        worx={booking.Worx}
                        to_time={booking.to_time}
                        from_time={booking.from_time}
                        
                        cost={booking.totalAmount}
                    />
                ))}
            </div>
        ));
    };

    const handleToggle = (upcoming) => {
        if (upcoming === isUpcoming) {
            return;
        }
        setShowBookings(false);
        setTimeout(() => {
            setIsUpcoming(upcoming);
            setShowBookings(true);
        }, 300); // Match the timeout duration to the transition duration
    };

    return (
        <div className={`${styles.bgGray100} min-h-screen flex flex-col`}>
            <ResponsiveAppBar />
            <main className={styles.mainContent}>
                <div className={styles.buttonsDiv}>
                    <HoverEffectDiv
                        text="Upcoming"
                        onClick={() => handleToggle(true)}
                        isSelected={isUpcoming}
                    />
                    <HoverEffectDiv
                        text="Past"
                        onClick={() => handleToggle(false)}
                        isSelected={!isUpcoming}
                    />
                </div>
                <div className={styles.bookingsList}>
                    {showBookings && (
                        <div className={styles.bookings}>
                            {isUpcoming ? (
                                comingBookings.length > 0 ? (
                                    renderBookings(comingBookings)
                                ) : (
                                    <div className="text-center text-gray-500">No upcoming bookings.</div>
                                )
                            ) : (
                                pastBookings.length > 0 ? (
                                    renderBookings(pastBookings)
                                ) : (
                                    <div className="text-center text-gray-500">No past bookings.</div>
                                )
                            )}
                        </div>
                    )}
                </div>
            </main>
            <Footer/>
        </div>
    );
}
