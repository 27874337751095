import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useRecoilState } from "recoil"
import { userState } from "../../../State/userAtom"
import { logOut } from "../../../Api/firebaseService"
import { useMediaQuery } from "@mui/material"
import { AppBar, Toolbar, Container, Button, Avatar, Box, Menu, MenuItem, IconButton } from "@mui/material"
import PersonIcon from "@mui/icons-material/Person"
import BookmarkIcon from "@mui/icons-material/Bookmark"
import LogoutIcon from "@mui/icons-material/Logout"
import SettingsIcon from "@mui/icons-material/Settings"

export default function Header() {
    const [user, setUser] = useRecoilState(userState)
    const [isLoggedIn, setIsLoggedIn] = useState(user && Object.keys(user).length !== 0)
    const [isVenueUser, setIsVenueUser] = useState(isLoggedIn && user?.bbdUser.user.UserTypeId === 2)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const navigate = useNavigate()
    const isMobile = useMediaQuery("(max-width:600px)")

    useEffect(() => {
        setIsLoggedIn(user && Object.keys(user).length !== 0)
        setIsVenueUser(isLoggedIn && user?.bbdUser.user.UserTypeId === 2)
    }, [user])

    const handleLogIn = () => navigate("/login", { state: { isLogIn: true } })
    const handleRegister = () => navigate("/login", { state: { isLogIn: false } })
    const handleListVenue = () => navigate("/partner-with-us")
    const handleMyBookings = () => navigate("/my-bookings")
    const handleMyProfile = () => navigate("/account")

    const handleLogout = () => {
        setAnchorEl(null)
        setIsLoggedIn(false)
        setIsVenueUser(false)
        setUser(null)
        logOut()
        navigate("/")
    }

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    return (
        <AppBar
            position="sticky"
            sx={{
                backgroundColor: "background.paper",
                backdropFilter: "blur(10px)",
                borderBottom: "1px solid",
                borderRight: "1px solid",
                borderLeft: "1px solid",
                borderColor: "divider",
                boxShadow: "none",
                width: "calc(100% - 2rem)",
                left: "1rem",
                right: "1rem",
            }}
        >
            <Toolbar sx={{ justifyContent: "space-between", px: 2 }}>
                {/* Logo */}
                <Box
                    component="a"
                    href="/"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        "&:hover": { opacity: 0.9 },
                    }}
                >
                    <img
                        src="/logos/Logo_black.png"
                        alt="Worxinn Logo"
                        style={{
                            height: isMobile ? "32px" : "40px",
                            width: "auto",
                        }}
                    />
                </Box>

                {/* Navigation */}
                {!isMobile && !isLoggedIn && (
                    <Button
                        sx={{
                            color: "text.primary",
                            "&:hover": { color: "primary.main" },
                            fontWeight: 500,
                        }}
                        onClick={handleListVenue}
                    >
                        List your venue
                    </Button>
                )}

                {/* User Menu */}
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    {isLoggedIn ? (
                        <>
                            <IconButton
                                onClick={handleMenuOpen}
                                sx={{
                                    border: "1px solid",
                                    borderColor: "divider",
                                    borderRadius: "24px",
                                    padding: "4px",
                                    "&:hover": { backgroundColor: "action.hover" },
                                }}
                            >
                                <Avatar
                                    src={user.bbdUser.user.profile_photo}
                                    alt={user.bbdUser.user.firstName}
                                    sx={{ width: 32, height: 32 }}
                                >
                                    <PersonIcon />
                                </Avatar>
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        minWidth: 200,
                                        mt: 1.5,
                                        overflow: "visible",
                                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
                                        "&:before": {
                                            content: '""',
                                            display: "block",
                                            position: "absolute",
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: "background.paper",
                                            transform: "translateY(-50%) rotate(45deg)",
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: "right", vertical: "top" }}
                                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                            >
                                <Box sx={{ px: 2, py: 1.5 }}>
                                    <Box sx={{ mb: 1 }}>
                                        <Box sx={{ fontSize: 14, fontWeight: 500 }}>{user.bbdUser.user.firstName}</Box>
                                        <Box sx={{ fontSize: 12, color: "text.secondary" }}>{user.bbdUser.user.email}</Box>
                                    </Box>
                                </Box>
                                {isVenueUser ? (
                                    <>
                                        <MenuItem
                                            onClick={() => {
                                                handleMenuClose()
                                                navigate("/venue-management")
                                            }}
                                        >
                                            <SettingsIcon sx={{ mr: 1, fontSize: 20 }} />
                                            Manage Venue
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                handleMenuClose()
                                                navigate("/venue-settings")
                                            }}
                                        >
                                            <SettingsIcon sx={{ mr: 1, fontSize: 20 }} />
                                            Venue Settings
                                        </MenuItem>
                                    </>
                                ) : (
                                    <>
                                        <MenuItem
                                            onClick={() => {
                                                handleMenuClose()
                                                handleMyProfile()
                                            }}
                                        >
                                            <PersonIcon sx={{ mr: 1, fontSize: 20 }} />
                                            Account
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                handleMenuClose()
                                                handleMyBookings()
                                            }}
                                        >
                                            <BookmarkIcon sx={{ mr: 1, fontSize: 20 }} />
                                            My Bookings
                                        </MenuItem>
                                    </>
                                )}
                                <MenuItem onClick={handleLogout}>
                                    <LogoutIcon sx={{ mr: 1, fontSize: 20 }} />
                                    Log out
                                </MenuItem>
                            </Menu>
                        </>
                    ) : (
                        <>
                            {!isMobile && (
                                <Button
                                    sx={{
                                        color: "text.primary",
                                        "&:hover": { color: "primary.main" },
                                        fontWeight: 500,
                                    }}
                                    onClick={handleLogIn}
                                >
                                    Log in
                                </Button>
                            )}
                            <Button
                                sx={{
                                    backgroundColor: "#ff4500",
                                    color: "white",
                                    "&:hover": { backgroundColor: "#ff6a33" },
                                    fontWeight: 500,
                                    textTransform: "none",
                                    px: 3,
                                }}
                                onClick={handleRegister}
                            >
                                Register
                            </Button>
                        </>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    )
}

