import { Offer } from "../Models/offerDetails";
import { SERVER_URL } from "../Utils/constants";
import { ApiService } from "./apiService";

export default class OfferAPIService {
  private apiService: ApiService;

  constructor() {
    this.apiService = new ApiService(SERVER_URL);
  }

  public async getOffer(id: number): Promise<any> {
    if (!id) {
      return null;
    }
    const resp = await this.apiService.get(`offers/details/${id}`);
    return resp;
  }

  public async updateOffer(id: number, newOffer: Offer) {
    const id_ = "" + id;
    return await this.apiService.update(`offers/update`, id_, newOffer);
  }

  public async createOffer(newOffer: Offer) {
    return await this.apiService.create(`offers/create`, newOffer);
  }

  public async deleteOffer(id: number) {
    return await this.apiService.delete(`offers/delete/${id}`);
  }

  public async getAllOffers() {
    return await this.apiService.get(`offers/list`)
  }
}
