import React from "react";
import { useState, useEffect } from "react";
import { SpaceDetail } from "../Models/spaceDetails.ts";
import EditableTextField from "../Components/editableTextField.tsx";
import { useDebounce } from "../Hooks/usedDebounce.tsx";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../Components/header.tsx";
import { AnimatePresence, motion } from "framer-motion";
import { WorxDetail } from "../Models/worxDetails.ts";
import { Photo } from "../Models/photos.ts";
import '../css/worxContentManagement.css'
import { BiSave } from "react-icons/bi";
import OfferAPIService from "../Api/offerAPIService.ts";

interface OfferManagementPageProps {
  /*w-orx: WorxDetail;
  userIntials: string;
  parentSpace: SpaceDetail;
  availableAmenities: any;*/
}

interface Field {
  name: string;
  label: string;
  type: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function OfferManagementPage({
  /*worx,
  userIntials,
  parentSpace,
  availableAmenities*/
}: OfferManagementPageProps) {
  const terms = `The standard Lorem Ipsum passage, used since the 1500s
"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."

Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC
"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"

1914 translation by H. Rackham
"But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?"

Section 1.10.33 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC
"At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat."

1914 translation by H. Rackham
"On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be welcomed and every pain avoided. But in certain circumstances and owing to the claims of duty or the obligations of business it will frequently occur that pleasures have to be repudiated and annoyances accepted. The wise man therefore always holds in these matters to this principle of selection: he rejects pleasures to secure other greater pleasures, or else he endures pains to avoid worse pains."`;

  const navigate = useNavigate();

  const convertListOfPhotosToRecord = (
    photos: Photo[]
  ): Record<number, string> => {
    const recordObj: Record<number, string> = {};
    for (let i = 0; i < photos.length; i++) {
      recordObj[i] = photos[i].photo_url;
    }
    return recordObj;
  };

  const offersAPIService = new OfferAPIService();

  const fieldStyle = "border border-gray-400 p-2 mb-4 rounded-lg";

  const [popupBottomMargin, setPopupBottomMargin] = useState("5vh");
  const [currentSelectedEditableField, setCurrentSelectedEditableField] = useState<string>("");
  const [offer, setOffer] = useState({ offer_name: 'test name' });
  const isEditable = true;

  const placeholder =
    "https://portnature.com.tr/assets/frontend/images/indoorpool/indoorpool.webp";

  const placeholderDescription =
    "lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua";

  function updateProperty<T extends Record<string, any>>(
    obj: T,
    propName: string,
    newValue: any
  ): T {
    if (Object.prototype.hasOwnProperty.call(obj, propName)) {
      return { ...obj, [propName]: newValue };
    }
    throw new Error(`Property ${propName} does not exist on object`);
  }

  useEffect(() => {
    const getOffer = async () => {
      const offerResponse = await offersAPIService.getOffer(2)

      if (offerResponse && offerResponse.body) {
        setOffer({
          offer_name: offerResponse.body.offer_name || 'Default Name',
          description: offerResponse.body.description || 'Default Description',
          // Add other fields as necessary
        });

        console.log(offer)
      } else {
        console.error("Invalid API response", offerResponse);
      }
    }

    getOffer();

  }, []);

  const handleEditableTextSelection = (field: string) => {
    //setCurrentSelectedEditableField(field);
    handleImageEditorVisability(field !== "");
  };

  const updateWorxDetailsWithFields = async (fieldsToUpdate: {
    [key: string]: string;
  }) => {
    let worxDetail = { ...worxData };

    // Update worxDetail with fields from fieldsToUpdate
    Object.keys(fieldsToUpdate).forEach((field) => {
      worxDetail = { ...worxDetail, [field]: fieldsToUpdate[field] };
    });

    // Update worxDetail with location data if available
    if (locationData) {
      Object.keys(locationData).forEach((key) => {
        worxDetail = { ...worxDetail, [key]: locationData[key] };
      });
    }

    console.log(worxDetail)

    setWorxData(worxDetail);
    return worxDetail;
  };

  const handleFieldSave = async (field: string, value: any) => {
    console.log(field, value)
    const isObject = !value.text;
    let newWorx: WorxDetail | null = null;

    const val = !isObject ? value.text : value;

    if (isObject && field !== "price") {
      newWorx = await updateWorxDetailsWithFields(value);
    } else {
      console.log("Else")
      newWorx = setSpace(field, val);
    }
    console.log(newWorx)
    if (newWorx === null || value === undefined) return;

    //checks if account is activated
    if (doesUserNameContainQuestionMark) return;

    let newWorxList = worxListCache.filter(
      (cachedWorx: WorxDetail) => cachedWorx.id !== newWorx!.id
    );

    newWorxList.push(newWorx!);

    setWorxListCache(newWorxList);

    let newSpaceList = spaceListCache.filter(
      (cachedSpace: SpaceDetail) => cachedSpace.id !== parentId
    );

    newSpaceList.push(parentSpace);

    setSpaceListCache(newSpaceList);

    setWorxData(newWorx!);
  };

  const debouncedHandleFieldSave = useDebounce(handleFieldSave, 100);
  const dataURLtoFile = (dataurl: string, filename: string) => {
    var arr = dataurl.split(",") as any,
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const getTimeInMillis = () => {
    return new Date().getTime();
  };

  const handleSaveAllChanges = async () => {
    if (userIntials === "??") {
      setShowActivationModal(true);
      return;
    }

    if (!worxData) return;

    console.log(worxData)

    const resp = await worxAPIService.updateWorx(worxData.id, worxData);


  };



  const listToRecord = (arr: string[]): Record<number, string> => {
    const recordObj: Record<number, string> = {};
    for (let i = 0; i < arr.length; i++) {
      recordObj[i] = arr[i];
    }
    return recordObj;
  };


  return (
    <AnimatePresence>
      <div className="w-full flex flex-col items-center relative overflow-hidden">

        <Header userInitials={'TT'} />


        <div className="fixed w-screen h-screen items-center justify-center z-[999] flex pointer-events-none">
          <div className="w-[89vw] lg:w-[70vw] max-w-[1280px] h-full flex pointer-events-none">

            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.5 }}
              className="w-full h-full flex "
            >
              <div
                style={{
                  transition: "margin-bottom 1s ease-in-out",
                  marginBottom: popupBottomMargin,
                }}
                onClick={handleSaveAllChanges}
                className="w-[130px] h-[38px] pointer-events-auto text-lg mt-auto ml-auto transition-all duration-300 shadow hover:scale-[1.03] 
                  hover:outline-dashed hover:outline-[3px] hover:outline-orange-400/40 hover:shadow-md hover hover:outline-offset-2 cursor-pointer rounded-lg bg-orange-500 
                  py-1 px-3 font-semibold flex flex-row items-center justify-center outline-orange-400/40 outline outline-[0.1px] "
              >
                <BiSave className="mr-2 text-white text-xl" />
                <div className="text-white">{"Save"}</div>
              </div>
            </motion.div>

          </div>
        </div>

        <div
          style={{
            transition: "margin-top 0.5s ease-in-out",
            marginTop: '20px',
          }}
          className={`min-h-[85vh] px-3`}
        >
          <div
            className={`pointer-events-none h-full w-[89vw] lg:w-[70vw] max-w-[1280px] flex flex-col items-center`}
          >
            {console.log(offer)}
            <div id="title-section" className="flex flex-col w-full mt-4 mb-4">
              <div className="w-full mt-2">
                <EditableTextField
                  label="Space Name"
                  currentSelectedEditableField={currentSelectedEditableField}
                  initialValue={offer.offer_name}
                  onSave={(value) => {
                    debouncedHandleFieldSave("name", value);
                  }}
                  tailwindTextStyle="text-xl font-bold"
                  onSelect={(currentSelectedLabel) => {
                    handleEditableTextSelection(currentSelectedLabel);
                  }}
                  onDeselect={() => {
                    handleEditableTextSelection("");
                  }}
                  isEditable={isEditable}
                />
              </div>

              {/* Placeholder image */}
              <div className="mt-4 flex justify-center items-center">
                <img
                  src={offer?.image_url || placeholder}
                  alt="Placeholder"
                  className="w-[95%] h-[400px] object-cover rounded-lg shadow-md"
                />
              </div>
            </div>
            <div
              id="description-section"
              className={`flex flex-col w-full mt-2 ${currentSelectedEditableField == "Description"
                ? "z-[3]"
                : "z-[1]"
                }`}
            >
              <div className="text-md font-semibold focus:outline-0 w-full mb-2">
                Description
              </div>
              <EditableTextField
                label="Description"
                currentSelectedEditableField={currentSelectedEditableField}
                initialValue={offer.description}
                onSave={(value) =>
                  debouncedHandleFieldSave("description", value)
                }
                tailwindTextStyle={`text-sm ${currentSelectedEditableField == "Description"
                  ? "text-black/100"
                  : "text-black/40"
                  }`}
                onSelect={(currentSelectedLabel) => {
                  handleEditableTextSelection(currentSelectedLabel);
                }}
                onDeselect={() => {
                  handleEditableTextSelection("");
                }}
                isEditable={isEditable}
              />
            </div>
          </div>
          <div className="text-md font-semibold focus:outline-0 w-full mb-2 mt-4">
            Price
          </div>
        </div>
        <div className="w-full h-[20vh] bg-orange-600 flex flex-col items-center object-cover mt-[120px]">
          <div className=" h-full w-[70vw] max-w-[1280px] flex flex-col items-center justify-center"></div>
        </div>
      </div>
    </AnimatePresence >
  );
}
