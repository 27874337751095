import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userState } from "../State/userAtom";
import { SpaceDetail } from "../Models/spaceDetails";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useRecoilState } from "recoil";
import { writeUser } from "../Api/firebaseService";
import User from "../Models/adminUser";
import { MdOutlineCreate } from "react-icons/md";
import OfferAPIService from "../Api/offerAPIService";
import "@splidejs/react-splide/css";
import SpaceDisplaySlider from "./spaceDisplaySlider";
import { SERVER_URL } from "../Utils/constants";
import { Offer } from "../Models/offerDetails";

type OfferDisplayType = {
  isEditable: boolean;
  currentSpaceId: number;
  parentImage?: string;
  space: SpaceDetail
};

const LoadingScreen = () => (
  <div className="w-full h-[17vh] flex items-center justify-center text-2xl">
    <AiOutlineLoading3Quarters className="animate-spin" />
  </div>
);

const OfferDisplay: React.FC<OfferDisplayType> = ({
  isEditable,
  currentSpaceId,
  parentImage = "https://www.pngkey.com/png/detail/470-4703342_generic-placeholder-image-conference-room-free-icon.png",
  space
}) => {
  const [currentUser, setCurrentUser] = useRecoilState(userState);
  const offerAPIService = new OfferAPIService();
  const placeholder = parentImage;
  const nav = useNavigate();

  const handleCreateOffer = async () => {
    if (!isEditable) return;

    const offer: Offer = {
      id: 0,
      offer_name: "test",
      description: "",
      original_price: 0.0,
      discounted_price: 0.0,
      offer_type_id: 1,
      terms_and_conditions: "",
      amenity_link: "",
      start_date: null,
      end_date: null,
      isActive: true,
      venue_id: currentSpaceId,
    };

    console.log(currentUser)
    const currentUserPermissionLevel = currentUser.bbdUser.user.Space[0] == currentSpaceId || currentUser.bbdUser.Space[0] == currentSpaceId ? 1 : 0;

    if (currentUserPermissionLevel === false) {
      return;
    }

    const respCreateOffer = await offerAPIService.createOffer(offer);

    console.log(respCreateOffer)
    nav(`/offer/edit/${respCreateOffer.body.id}`)
  };

  const offers = []


  return (
    <div className="flex flex-col items-start translate-x-[-8px]">
      {offers.length !== 0 && (
        <SpaceDisplaySlider
          worxes={worxes}
          placeholder={placeholder}
          currentSpaceId={currentSpaceId + ""}
          typeOfSpaceId={typeOfSpaceId}
        />
      )}

      <div
        onClick={handleCreateOffer}
        className="outline translate-x-[8px] bg-orange-500 rounded-xl text-[20xp] h-[60px] outline-2 py-2 px-10 text-white text-center max-h-[100px] outline-orange-400/[15%] flex justify-center items-center font-bold mt-2 overflow-hidden hover:scale-[1.01] transition-all duration-400 object-cover cursor-pointer hover:outline-dashed hover:outline-[3px] hover:outline-orange-400/50 hover hover:outline-offset-2 shadow-md hover:shadow-none"
      >
        <MdOutlineCreate className="mr-2 text-2xl opacity-80" />
        {`Create Offer`}
      </div>
    </div>
  );
};

export default OfferDisplay;
